import React, { useContext, useState } from "react";

import { IoMdArrowForward } from "react-icons/io";
import { FilterContext } from "../contexts/FilterContext";

const FilterBar = ({ category }) => {
  const { isOpenFilter, handleCloseFilter, filters, setFilters } =
    useContext(FilterContext);

  
  const navigation = [
    { name: "Beer", href: "/beer", current: true, subcategories: [] },
    {
      name: "Wine",
      href: "/wine",
      current: false,
      subcategories: ["Red Wine", "Champagne", "White Wine", "Rose"],
    },
    {
      name: "Spirits",
      href: "/spirits",
      current: false,
      subcategories: ["Whisky", "Vodka", "Gin", "Brandy & Cognac", "Rum"],
    },
    {
      name: "More",
      href: "/more",
      current: false,
      subcategories: [
        "Soda",
        "Tonic",
        "Juice",
        "Water",
        "Gifts & Games",
        "Snacks",
        "Smokes",
        "Condoms & Lubes",
        "Convenience Items",
      ],
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState("");

  const filteredCategories = navigation.filter(
    (item) => item.name.toLowerCase() === category.toLowerCase()
  );

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    // Update the selected category in the filters context state
    setFilters({ ...filters, category: e.target.value });
  };

  const handlePriceChange = (e, type) => {
    const value = parseFloat(e.target.value);
    setFilters({
      ...filters,
      [type]: isNaN(value) ? 0 : value,
    });
  };

  // const handleCategoryChange = (category) => {
  //   setFilters({ ...filters, category });
  // };

  const handleTagChange = (tag) => {
    setFilters({ ...filters, tag });
  };

  const handleVolumeChange = (volume) => {
    setFilters({ ...filters, volume });
  };
  return (
    <div
      className={`${
        isOpenFilter ? "left-0" : "-left-full"
      }  bg-white fixed top-0 h-full shadow-2xl w-1/2 md:w-1/3 lg:w-1/4 transition-all duration-300 z-20 px-4 "`}

      // className="w-1/4 flex
      //  bg-white fixed z-20 top-0 h-full transition-all duration-300 mr-4"
    >
      <div className="mb-8">
        <div className="flex justify-between pt-10">
          <h2 className="text-lg font-semibold mb-4">Filter By:</h2>
          <h2
            onClick={handleCloseFilter}
            className="font-bold text-2xl cursor-pointer">
            X
          </h2>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Price
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              placeholder="Min"
              value={filters.minPrice}
              onChange={(e) => handlePriceChange(e, "minPrice")}
              className="w-1/2 px-2 py-2 border border-gray-300 rounded"
            />
            <input
              type="number"
              placeholder="Max"
              value={filters.maxPrice}
              onChange={(e) => handlePriceChange(e, "maxPrice")}
              className="w-1/2 px-2 py-2 border border-gray-300 rounded"
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Categories
          </label>
          <select
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="w-full px-2 py-2 border border-gray-300 rounded">
            <option value="">Select a Category</option>
            {filteredCategories.length > 0 &&
              filteredCategories[0].subcategories.map((subcategoryItem) => (
                <option key={subcategoryItem} value={subcategoryItem}>
                  {subcategoryItem}
                </option>
              ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Tags
          </label>
          {/* Add your tag options here */}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Volume
          </label>
          {/* Add your volume options here */}
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
