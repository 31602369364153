import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ShippingDetailsContext } from "../contexts/ShippingDetailsContext";
import { AuthContext } from "../contexts/AuthContext";

const AccountDetailsForm = () => {
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);

  const { formData, setFormData, formIncomplete, setFormIncomplete } =
    useContext(ShippingDetailsContext);
  const { userID } = useContext(AuthContext);

  useEffect(() => {
    const fetchedDeliveryData = localStorage.getItem("deliveryData");
    // setFormData()
  }, []);

  useEffect(() => {
    let data = JSON.stringify({
      uid: userID,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://webapp.chupachap.co.ke/run/getlocation.php",
      // url: "/getlocation.php",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data) {
          setDeliveryAddresses(response.data);
        }
      })
      .catch((error) => {
        setDeliveryAddresses([]); //TODO:: Release this once testing is done
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
    // setFormDataCollected({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  const saveToLocal = () => {
    //TODO::Wite function to save data to local for future autofills

    localStorage.setItem("deliveryData", JSON.stringify(formData));
  };

  return (
    <div className="container  lg:w-2/3 mx-auto my-8 p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-bold mb-6">ACCOUNT DETAILS</h2>
      <form onSubmit={handleSubmit}>
        <div className="lg:flex sm:flex-column mx-auto justify-between gap-2">
          <div className="mb-4 lg:w-1/2">
            <label className="block text-gray-600 text-sm font-semibold mb-2">
              First name *
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-orange-500"
            />
          </div>
          <div className="mb-4 lg:w-1/2">
            <label className="block text-gray-600 text-sm font-semibold mb-2">
              Last name (optional)
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-orange-500"
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-semibold mb-2">
            Phone *
          </label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-orange-500"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-semibold mb-2">
            Email address *
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-orange-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-semibold mb-2">
            Delivery Address *
          </label>
          <select
            name="deliveryAddress"
            value={formData.deliveryAddress}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-orange-500">
            <option disabled value="">
              Select Delivery Address
            </option>
            {deliveryAddresses.map((address, index) => (
              <option key={index} value={address.id}>
                {address.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex mx-auto justify-between gap-2">
          <div className="mb-4 w-1/2">
            <label className="block text-gray-600 text-sm font-semibold mb-2">
              Location *
            </label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-orange-500"
            />
          </div>
          <div className="mb-4 w-1/2">
            <label className="block text-gray-600 text-sm font-semibold mb-2">
              House *
            </label>
            <input
              type="text"
              name="house"
              value={formData.house}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-orange-500"
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-gray-600 text-sm font-semibold mb-2">
            Comments (optional)
          </label>
          <textarea
            name="deliveryComments"
            value={formData.deliveryComments}
            onChange={handleChange}
            rows="4"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-orange-500"></textarea>
        </div>
        <button
          onClick={saveToLocal}
          type="submit"
          className="bg-orange-600 text-white py-3 px-6 hover:bg-orange-700 focus:outline-none focus:ring focus:border-orange-300">
          SAVE DETAILS
        </button>
      </form>
    </div>
  );
};

export default AccountDetailsForm;
