import React, { createContext, useState } from "react";

export const FilterContext = createContext();

const FilterProvider = ({ children }) => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filters, setFilters] = useState({
    priceRange: 50, // Initial price range value (example)
    categories: [], // Selected categories
    tags: [], // Selected tags
  });

  const handleCloseFilter = () => {
    setIsOpenFilter(false);
  };

  return (
    <FilterContext.Provider
      value={{
        isOpenFilter,
        setIsOpenFilter,
        handleCloseFilter,
        filters,
        setFilters,
      }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
