import React, { createContext, useState } from "react";
import { AdvertContext } from "./AdvertsContext";
import { useContext } from "react";

export const BannerContext = createContext();

function BannerProvider({ children }) {
  const { adverts } = useContext(AdvertContext);

  const banners = adverts.filter(
    (item) =>
      item.name.toLowerCase() === "section_3" ||
      item.name.toLowerCase() === "section _6"
  );

  console.log("Filtered Banenrs", banners);
  // const [banners, setBanners] = useState([
  //   {
  //     id: 1,
  //     image: require("../assets/Wines-Leaderboard-1.png"),
  //   },
  //   {
  //     id: 2,
  //     image: require("../assets/Beers-Leaderboard-3.png"),
  //   },
  // ]);

  const [bestBanners, setBestBanners] = useState([
    {
      src: require("../assets/banner-left.png"),
      description: "Gin",
    },
    {
      src: require("../assets/Wines-Leaderboard-1.png"),
      description: "Rum",
    },
    {
      src: require("../assets/Beers-Leaderboard-3.png"),
      description: "Whisky",
    },
    {
      src: require("../assets/Beers-Leaderboard-3.png"),
      description: "Vodka",
    },
  ]);

  return (
    <BannerContext.Provider value={{ banners, bestBanners }}>
      {children}
    </BannerContext.Provider>
  );
}

export default BannerProvider;
