import React from "react";
import loaderlogo from "../../src/assets/chupasimple.png";

export default function ErrorScreen() {
  return (
    <section className="h-screen flex flex-col justify-center items-center">
      <div className="mb-4">
        <img src={loaderlogo} alt="Chupa Chap Logo" />
      </div>
      <div className="text-center">
        <h2 className="text-2xl font-semibold mb-2">
          Oops! Looks like we are out 🍾🍾
        </h2>
        <p className="text-gray-500">
          We couldn't find any products at the moment. Please try again later.
        </p>
      </div>
    </section>
  );
}
