import React, { createContext, useState } from "react";
import { useContext } from "react";
import { AdvertContext } from "./AdvertsContext";

export const DoubleGridAdsContext = createContext();

function DoubleGridAdsProvider({ children }) {
  // const [dgridbanners, setDGridbanners] = useState([
  //   {
  //     id: 1,
  //     image: require("../assets/600x30001.png"),
  //   },
  //   {
  //     id: 2,
  //     image: require("../assets/600x30002.png"),
  //   },
  // ]);

  const { adverts } = useContext(AdvertContext);

  const dgridbanners = adverts.filter(
    (item) => item.name.toLowerCase() === "section_5"
  );

  console.log("double grid", dgridbanners);

  // const filteredItems = items.filter((item) => item.name === "section 1");

  // useEffect(() => {
  //   // fetch data
  //   const fetchProducts = async () => {
  //     const response = await fetch("https://fakestoreapi.com/products");
  //     const data = await response.json();
  //     setProducts(data);
  //   };

  //   fetchProducts();
  // }, []);

  return (
    <DoubleGridAdsContext.Provider value={{ dgridbanners }}>
      {children}
    </DoubleGridAdsContext.Provider>
  );
}

export default DoubleGridAdsProvider;
