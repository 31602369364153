// ... (import statements)

const Stepper = ({
  currentStep,
  setCurrentStep,
  isPaymentComplete,
  payRequestSent,
  cart,
  formData,
}) => {
  const steps = ["Cart", "Checkout", "Complete"];

  const handleStepClick = (stepIndex) => {
    console.log("index", stepIndex, "data", cart.length, formData);
    if (
      stepIndex === 0 ||
      (stepIndex === 1 && cart.length > 0) || // Check for cart not empty
      (stepIndex === 2 &&
        formData.paymethod !== "" &&
        formData.firstName !== "" &&
        formData.lastName !== "" &&
        formData.phone !== "" &&
        formData.email !== "" &&
        formData.deliveryAddress !== "" &&
        formData.location !== "" &&
        formData.house !== "" &&
        formData.paymethod !== "")
    ) {
      setCurrentStep(stepIndex);
    }
  };

  return (
    <div className="flex justify-between w-full my-8 gap-2 ">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`flex cursor-pointer  ${
            index < currentStep ||
            (index === 2 && isPaymentComplete && payRequestSent)
              ? "text-orange-600"
              : "text-gray-500"
          } border border-gray-200 w-full px-5 py-5`}
          onClick={() => handleStepClick(index)}>
          <div
            className={`h-10 w-10 rounded-full border-2 flex items-center justify-center  text-2xl ${
              index < currentStep ||
              (index === 2 && isPaymentComplete && payRequestSent)
                ? "bg-orange-600 border-orange-600 text-white"
                : "bg-white border-gray-500 text-black"
            }`}>
            {index < currentStep ||
            (index === 2 && isPaymentComplete && payRequestSent)
              ? "✔"
              : index + 1}
          </div>
          <span className="ml-2 text-l font-bold items-center self-center">
            {step}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
// import React from "react";

// const Stepper = ({
//   currentStep,
//   setCurrentStep,
//   isPaymentComplete,
//   setIsPaymentComplete,
//   payRequestSent,
//   setPayRequestSent,
// }) => {
//   const steps = ["Cart", "Checkout Details", "Order Complete"];

//   const handleStepClick = (stepIndex) => {
//     setCurrentStep(stepIndex);
//   };

//   return (
//     <div className="flex justify-between w-full my-8 gap-2 ">
//       {steps.map((step, index) => (
//         <div
//           key={index}
//           className={`flex cursor-pointer  ${
//             index < currentStep ? "text-orange-600" : "text-gray-500"
//           } border border-gray-200 w-full px-5 py-5`}
//           onClick={() => handleStepClick(index)}>
//           <div
//             className={`h-10 w-10 rounded-full border-2 flex items-center justify-center  text-2xl ${
//               index < currentStep
//                 ? "bg-orange-600 border-orange-600 text-white"
//                 : "bg-white border-gray-500 text-black"
//             }`}>
//             {index < currentStep ? "✔" : index + 1}
//           </div>
//           <span className="ml-2 text-l font-bold items-center self-center">
//             {step}
//           </span>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Stepper;
