import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const navigation = [
    { name: "Beer", href: "/beer", current: true, subcategories: ["Cider"] },
    {
      name: "Wine",
      href: "/wine",
      current: false,
      subcategories: ["Red Wine", "Champagne", "White Wine", "Rose"],
    },
    {
      name: "Spirits",
      href: "/spirits",
      current: false,
      subcategories: ["Whisky", "Vodka", "Gin", "Brandy & Cognac", "Rum"],
    },
    {
      name: "More",
      href: "/more",
      current: false,
      subcategories: [
        "Vermouth & Shooters",
        "Sparkling",
        "Energy Drinks",
        "Convinience store",
        "Mixers",
      ],
    },
  ];

  return (
    <footer className="bg-black text-white pt-8">
      <div className="container mx-auto flex flex-wrap">
        {navigation.map((item) => (
          <div
            key={item.name}
            className="py-4 w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <h2 className="text-lg font-semibold mb-4 underline underline-offset-8 decoration-gray-500 decoration-2">
              {item.name.toUpperCase()}
            </h2>
            {Array.isArray(item.subcategories)
              ? // If subcategories is an array, map through them
                item.subcategories.map((subcategory, index) => (
                  <div key={index} className="my-2 font-medium cursor-pointer">
                    <Link
                      to={`/product-category/${item.name.toLowerCase()}/${subcategory.toLowerCase()}`}>
                      {subcategory}
                    </Link>
                  </div>
                ))
              : // If subcategories is an object with sections and items, map through them
                Object.entries(item.subcategories).map(([section, items]) => (
                  <div key={section}>
                    <p className="font-semibold py-2 whitespace-nowrap">
                      {section}
                    </p>
                    {items.map((subcategory, index) => (
                      <p
                        key={index}
                        className="my-2 font-medium cursor-pointer">
                        {`Cat-${subcategory}`}
                      </p>
                    ))}
                  </div>
                ))}
          </div>
        ))}
      </div>
      <div className="container mx-auto mt-6">
        <div className="flex justify-between w-full">
          <div>
            <h2 className="text-lg font-semibold mb-4 underline underline-offset-8 decoration-gray-500 decoration-2">
              CONTACTS
            </h2>
            <p>Phone:</p>
            <p className="text-sm font-medium my-2">+254 717 215 155</p>
            <p className="text-sm font-medium my-2">+254 735 215 155</p>
            <p className="text-sm font-medium my-2">
              Unga house, Westlands Nairobi
            </p>
          </div>
          <div className="w-full sm:w-1/2">
            <h2 className="text-lg font-semibold mb-4 underline underline-offset-8 decoration-gray-500 decoration-2">
              ABOUT US
            </h2>
          </div>
        </div>
      </div>
      <div className="text-center mt-6 bg-gray-900 w-full mb-0 py-4">
        <p className="text-gray-600">Chupachap© 2023 All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
