import React, { useContext } from "react";
import { CartContext } from "../contexts/CartContext";
import CartItemFull from "./CartItemFull";

function CartFullscreen({ cart }) {
  // const { cart, clearCart, totalQuantity, totalAmount } =
  //   useContext(CartContext);

  return (
    <div className="lg:w-2/3 ">
      <div className="flex flex-col gap-y-2 border-b">
        {/* if height doesn't work on bigger screens switch to 520px for small and 640px for larger */}
        {cart.length > 0 &&
          cart.map((item) => <CartItemFull cartItem={item} key={item.id} />)}
      </div>
    </div>
  );
}

export default CartFullscreen;
