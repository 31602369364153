import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

//import contexts
import ProductProvider from "./contexts/ProductContext";
import SidebarProvider from "./contexts/SidebarContext";
import ThreeGridAdsProvider from "./contexts/ThreeGridAdsContext";
import BannerProvider from "./contexts/BannerContext";
import DoubleGridAdsProvider from "./contexts/DoubleGridAdsContext";
import SectionHeaderProvider from "./contexts/SectionHeaderContext";
import FilterProvider from "./contexts/FilterContext";
import CartProvider from "./contexts/CartContext";
import AuthProvider from "./contexts/AuthContext";
import ShippingDetailsProvider from "./contexts/ShippingDetailsContext";
import { ToastContainer } from "react-toastify";
import AdvertProvider from "./contexts/AdvertsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <SidebarProvider>
      <ShippingDetailsProvider>
        <CartProvider>
          <FilterProvider>
            <ProductProvider>
              <AdvertProvider>
                <ThreeGridAdsProvider>
                  <DoubleGridAdsProvider>
                    <BannerProvider>
                      <SectionHeaderProvider>
                        <React.StrictMode>
                          <App />
                          <ToastContainer />
                        </React.StrictMode>
                      </SectionHeaderProvider>
                    </BannerProvider>
                  </DoubleGridAdsProvider>
                </ThreeGridAdsProvider>
              </AdvertProvider>
            </ProductProvider>
          </FilterProvider>
        </CartProvider>
      </ShippingDetailsProvider>
    </SidebarProvider>
  </AuthProvider>
);
