import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const range = 2; // Number of pages to show on each side of the current page
  let start = Math.max(1, currentPage - range);
  let end = Math.min(currentPage + range, totalPages);

  if (currentPage - start < range) {
    end = Math.min(start + 2 * range, totalPages);
    start = Math.max(1, end - 2 * range);
  }

  const pages = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  return (
    <div className="flex justify-center mt-8">
      <button
        className={`px-4 py-1 mr-2 ${
          currentPage === 1
            ? "bg-gray-300 cursor-not-allowed"
            : "bg-orange-600 hover:bg-orange-600"
        } text-white rounded`}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}>
        {"<"}
      </button>
      {start > 1 && (
        <>
          <button
            className={`px-2 py-1 mx-1 border-2 border-gray-400 bg-gray-300  hover:text-black font-bold rounded`}
            onClick={() => onPageChange(1)}>
            1
          </button>
          {start > 2 && <span className="mx-1">......</span>}
        </>
      )}
      {pages.map((page) => (
        <button
          key={page}
          className={`px-2 py-1 mx-1 ${
            currentPage === page
              ? "border-2 border-orange-600 text-orange-600 font-bold "
              : "border-2 border-gray-400 bg-gray-300  hover:text-black font-bold"
          } rounded`}
          onClick={() => onPageChange(page)}>
          {page}
        </button>
      ))}
      {end < totalPages && (
        <>
          {end < totalPages - 1 && <span className="mx-1">.....</span>}
          <button
            className={`px-2 py-1 mx-1 border-2 border-gray-400 bg-gray-300  hover:text-black font-bold rounded`}
            onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </button>
        </>
      )}
      <button
        className={`px-4 py-1 ml-2 ${
          currentPage === totalPages
            ? "bg-gray-300 cursor-not-allowed"
            : "bg-orange-600 hover:bg-orange-600"
        } text-white rounded`}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}>
        {">"}
      </button>
    </div>
  );
};

export default Pagination;
