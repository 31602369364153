import React, { useState, useEffect } from "react";
import heroBig from "../assets/BIG-BANNER.jpg";
import heroUpper from "../assets/Hero-Banner-52x.jpg";
import heroBottom from "../assets/Hero-Banner-Bottom-1.png";
import { useContext } from "react";
import { AdvertContext } from "../contexts/AdvertsContext";

const images = [heroBig, heroUpper, heroBottom];

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { adverts } = useContext(AdvertContext);

  const sliderBanners = adverts.filter(
    (item) =>
      item.name.toLowerCase() === "section_1" ||
      item.name.toLowerCase() === "section_2"
  );

  const sideBanners = adverts.filter(
    (item) => item.name.toLowerCase() === "section_2"
  );

  // console.log("fetched ads", sideBanners);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, 2400); // Change slide every 3 seconds (3000 milliseconds)

    return () => {
      clearInterval(interval);
    };
  }, []);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  const previousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="max-w-full mx-auto">
      <div className="grid grid-rows-none md:grid-cols-5 gap-1 md:gap-2">
        <div className="w-full h-full object-fill col-span-3 md:col-span-3 row-span-2 relative">
          <button
            className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-500 hover:text-white text-black opacity-80 h-10 w-10 content-center rounded-full shadow-md z-10 text-3xl border-white border-2 hover:border-orange-600  "
            onClick={previousSlide}>
            {"<"}
          </button>
          <button
            className="absolute top-1/2 right-4 transform -translate-y-1/2  bg-gray-500 hover:text-white text-black opacity-80 h-10 w-10 content-center rounded-full shadow-md z-10 text-3xl border-white border-2 hover:border-orange-600 "
            onClick={nextSlide}>
            {">"}
          </button>
          {sliderBanners &&
            sliderBanners.length > 0 &&
            sliderBanners.map((image, index) => (
              <div
                key={index}
                className={`absolute w-full h-full transition-opacity duration-500 ${
                  index === currentSlide ? "opacity-100" : "opacity-0"
                }`}>
                <img
                  className="w-full h-full object-fill col-span-3 md:col-span-3 row-span-2 relative"
                  src={image.photo}
                  alt={`Slide ${index + 1}`}
                />
              </div>
            ))}
        </div>
        {sideBanners &&
          sideBanners.length > 0 &&
          sideBanners.map((image, index) => {
            return (
              <img
                key={index}
                className="w-full h-full object-cover col-span-2"
                src={image.photo}
                alt="/"
              />
            );
          })}
      </div>
    </div>
  );
};

export default Hero;
