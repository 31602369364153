import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
// create context
export const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  // create products state
  const [products, setProducts] = useState([]);
  const [progress, setProgress] = useState(0);

  console.log("context progress", progress);
  useEffect(() => {
    const fetchProducts = () => {
      setProgress(30);
      let data = JSON.stringify({
        //TODO:: find solution for having to pass number
        username: "254723899726",
        cat: "cat",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://webapp.chupachap.co.ke/run/getallproduct.php",
        // url: "/getallproduct.php",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
        },
        data: data,
      };
      setProgress(50);
      axios
        .request(config)
        .then((response) => {
          setProgress(100);
          setProducts(response.data);
        })
        .catch((error) => {
          setProgress(100);
          setProducts([]);
        });
    };

    // fetch data TODO:: Remove this code once testing is done
    // const fetchProducts = async () => {
    //   const response = await fetch("https://fakestoreapi.com/products");
    //   const data = await response.json();
    //   setProducts(data);
    // };

    fetchProducts();
  }, []);

  const filterProducts = (category, subcategory) => {
    // Filter products based on category and subcategory
    // Update products state with filtered products
    // console.log("Products filtered", category, subcategory);
  };

  // TODO:: apply all the categories here for the products then pass them to provider together with the value to mantain conistency and have every page just calling the products thus use names that match categories e.g bestselling, wines, beers, etc....For the products with pages, we will just fetch all products then filter
  return (
    <ProductContext.Provider
      value={{ products, filterProducts, progress, setProgress }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
