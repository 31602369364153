import React from "react";
import bannerbeer from "../assets/Beers-Leaderboard-3.png";
import bannerwine from "../assets/Wines-Leaderboard-1.png";
import bannerwhisky from "../assets/Whisky-of-the-Month.png";
import bannerspirits from "../assets/Liqueurs-Leaderboard-2.png";
import bannerstore from "../assets/Convenience-Store-Leaderboard.png";

function ProductPageBanner({ category }) {
  let banner;

  switch (category) {
    case "beer":
      banner = bannerbeer;
      break;
    case "wine":
      banner = bannerwine;
      break;
    case "whisky":
      banner = bannerwhisky;
      break;
    case "spirits":
      banner = bannerspirits;
      break;
    case "more":
      banner = bannerstore;
      break;
    default:
      // Default banner or fallback logic if category is not recognized
      banner = bannerstore; // You can set a default banner here
      break;
  }

  return (
    <div className="w-full h-full object-fit">
      <img className="h-full w-full" src={banner} alt={`${category} banner`} />
    </div>
  );
}

export default ProductPageBanner;
