import React, { createContext, useState } from "react";
import { useContext } from "react";
import { AdvertContext } from "./AdvertsContext";

export const ThreeAdsGridContext = createContext();

function ThreeGridAdsProvider({ children }) {
  // const [gridbanners, setGridbanners] = useState([
  //   {
  //     id: 1,
  //     image: require("../assets/Gin-of-the-Month.png"),
  //   },
  //   {
  //     id: 2,
  //     image: require("../assets/Wine-of-the-Month.png"),
  //   },
  //   {
  //     id: 3,
  //     image: require("../assets/Whisky-of-the-Month.png"),
  //   },
  // ]);

  const { adverts } = useContext(AdvertContext);

  const gridbanners = adverts.filter(
    (item) => item.name.toLowerCase() === "section_4"
  );

  console.log("three grid", gridbanners);

  // useEffect(() => {
  //   // fetch data
  //   const fetchProducts = async () => {
  //     const response = await fetch("https://fakestoreapi.com/products");
  //     const data = await response.json();
  //     setProducts(data);
  //   };

  //   fetchProducts();
  // }, []);

  return (
    <ThreeAdsGridContext.Provider value={{ gridbanners }}>
      {children}
    </ThreeAdsGridContext.Provider>
  );
}

export default ThreeGridAdsProvider;
