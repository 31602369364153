import React, { useContext, useState } from "react";
import { CartContext } from "../contexts/CartContext";
import { ShippingDetailsContext } from "../contexts/ShippingDetailsContext";

const OrderSummary = ({ onNext }) => {
  const { cart, totalAmount } = useContext(CartContext);
  const { formData, setFormData } = useContext(ShippingDetailsContext);
  const isFormDataComplete =
    formData.firstName !== "" &&
    // formData.lastName !== "" &&
    formData.phone !== "" &&
    formData.email !== "" &&
    formData.deliveryAddress !== "" &&
    formData.location !== "" &&
    formData.house !== "" &&
    // formData.deliveryComments !== "" &&
    formData.paymethod !== "";

  const shippingFee = 150; //TODO:: Confirm on shipping fee

  // const [selectedPayment, setSelectedPayment] = useState("");

  const handleInputChange = (event) => {
    // setSelectedPayment(event.target.value);
    setFormData({
      ...formData,
      paymethod: event.target.value,
    });
  };

  const completeOrder = () => {
    if (formData) {
      onNext();
    } else {
    }
  };

  return (
    <div className="container lg:w-2/5 mx-auto my-8 py-8 px-4 bg-white shadow-lg  border border-orange-600">
      <h2 className="text-xl font-bold mb-4">YOUR ORDER</h2>
      <div className="flex justify-between mb-4">
        <div className="w-1/2">
          <p className="font-bold text-sm">PRODUCT</p>
        </div>
        <div className="w-1/4 text-right">
          <p className="font-bold text-sm">SUBTOTAL</p>
        </div>
      </div>
      <div className="mb-4 text-sm">
        {cart.length > 0 &&
          cart.map((cartItem, index) => {
            return (
              <div key={index} className="flex justify-between">
                <p className="w-2/3 text-sm">
                  {cartItem.gen_name} X{" "}
                  <span className="font-bold"> {cartItem.qty}</span>
                </p>
                <p className="font-semibold text-sm">
                  Kshs.{parseFloat(cartItem.price * cartItem.qty).toFixed(2)}
                </p>
              </div>
            );
          })}
      </div>
      <div className="flex justify-between mb-4 ">
        <div className="w-2/3 text-sm ">
          <p>Subtotal</p>
          <p>Shipping Fee</p>
          <p className="font-bold mt-2">Total</p>
        </div>
        <div className="w-1/3 text-sm text-right ">
          <p>KShs {totalAmount}</p>
          <p>(added to total)</p>
          <p className="font-bold mt-2">
            KShs {parseFloat(totalAmount).toFixed(2)}
          </p>
        </div>
      </div>
      <div className=" my-8 bg-white ">
        <h2 className="text-sm font-bold mb-6">Select Payment Method:</h2>
        <div className="flex flex-col mb-4">
          {/* <div className="flex items-center mb-2">
            <input
              type="radio"
              id="cashOnDelivery"
              name="paymentMethod"
              value="1"
              onChange={handleInputChange}
              className="mr-2"
            />
            <label htmlFor="cashOnDelivery">Cash on delivery</label>
          </div> */}
          <div className="flex items-center mb-2">
            <input
              type="radio"
              id="lipaNaMpesa1"
              name="paymentMethod"
              value="2"
              onChange={handleInputChange}
              className="mr-2"
            />
            <label htmlFor="lipaNaMpesa1">Lipa na M-PESA</label>
          </div>
        </div>
      </div>
      <button
        onClick={completeOrder}
        disabled={!isFormDataComplete}
        className={`${
          isFormDataComplete
            ? "bg-orange-600 hover:bg-orange-700"
            : "bg-gray-400"
        } text-white py-3 px-6   focus:outline-none focus:ring focus:border-orange-300`}>
        PLACE ORDER
      </button>
      <p className="mt-4 text-xs w-full">
        Your personal data will be used to process your order, support your
        experience throughout this website, and for other purposes described in
        our privacy policy.
      </p>
    </div>
  );
};

export default OrderSummary;
