import axios from "axios";
import React, { createContext, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { AuthContext } from "./AuthContext";

export const AdvertContext = createContext();

function AdvertProvider({ children }) {
  const { userID, invoiceNumber } = useContext(AuthContext);
  const [adverts, setAdverts] = useState([]);

  useEffect(() => {
    let data = JSON.stringify({
      uid: userID,
      phone: "254723899726",
      invoice: invoiceNumber,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://webapp.chupachap.co.ke/run/addadvert.php",
      // url: "/addadvert.php",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("Adverts", response.data);
        if (response.data.length > 0) {
          setAdverts(response.data);
        } else {
          setAdverts([]);
        }
      })
      .catch((error) => {
        console.log("adverts error", error);
      });
  }, []);

  return (
    <AdvertContext.Provider value={{ adverts }}>
      {children}
    </AdvertContext.Provider>
  );
}

export default AdvertProvider;
