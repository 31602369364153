import React from "react";

const LoginForm = () => {
  return (
    <div className="lg:w-1/2 p-8 border-r sm:w-full">
      <h2 className="text-2xl font-semibold mb-4">LOGIN</h2>
      <form>
        <div className="mb-4">
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-600">
            Username or Email Address
          </label>
          <input
            type="text"
            id="username"
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-600">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>
        <div className="flex items-center justify-between mb-4">
          <label htmlFor="remember" className="flex items-center">
            <input type="checkbox" id="remember" className="mr-2" />
            <span className="text-sm">Remember me</span>
          </label>
          <a href="#" className="text-sm text-blue-500 hover:underline">
            Lost your password?
          </a>
        </div>
        <button
          type="submit"
          className="bg-orange-600 text-white py-2 px-10 hover:bg-black">
          LOG IN
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
