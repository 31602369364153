import React from "react";
import AccountDetailsForm from "../components/AccountDetailsForm";
import OrderSummary from "../components/Ordersummary";

export const ShippingPaymentStep = ({ onNext, onPrev }) => {
  return (
    <div>
      {/* Shipping step content */}
      <div className=" w-full gap-4 lg:flex sm:flex-column">
        <AccountDetailsForm />
        <OrderSummary onNext={onNext} />
      </div>
      {/* <button onClick={onPrev} className="btn-secondary mr-2">
        Previous
      </button>
      <button onClick={onNext} className="btn-primary">
        Proceed to Complete order
      </button> */}
    </div>
  );
};
