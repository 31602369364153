import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Checkout from "./pages/Checkout";
import AuthModal from "./components/AuthModal";
import ProductsPage from "./pages/ProductsPage";

const App = () => {
  const [progress, setProgress] = useState(0);

  return (
    <div className="overflow-hidden">
      <Router>
        <Header />
        <AuthModal />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route
            path="product-category/:category/:subcategory"
            element={<ProductsPage />}
          />
          <Route path="product-category/:category" element={<ProductsPage />} />
        </Routes>
        {/* <AuthModal /> */}
        <Sidebar />
        {/* <FilterBar /> */}
        <Footer />
      </Router>
    </div>
  );
};

export default App;
