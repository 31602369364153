import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import LoadingBar from "react-top-loading-bar";

const Product = ({ product }) => {
  const {
    name,
    photo,
    description,
    id,
    category,
    subcategory,
    cost,
    tags,
    size,
    plevel,
  } = product;
  const [isHovered, setIsHovered] = useState(false);
  const { addToCart, progress, setProgress } = useContext(CartContext);

  return (
    <div className="border border-[#e4e4e4] shadow-xl flex flex-col py-6">
      <LoadingBar
        color="blue"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={3}
      />
      <div className="relative overflow-hidden group transition h-1/2">
        <div className="w-full h-full flex justify-center items-center">
          <Link to={`/product/${id}`}>
            <img
              className="max-h-[200px] group-hover:scale-110"
              src={
                photo ||
                "https://i0.wp.com/chupachap.co.ke/wp-content/uploads/2020/02/logo.png?fit=250%2C43&ssl=1"
              }
              alt="Chupa Chap"
            />
          </Link>
        </div>
        {tags && tags.includes("on-sale") && (
          <div className="absolute top-2 right-2 bg-orange-600 text-white rounded-full h-14 w-14 flex items-center justify-center font-bold">
            SALE
          </div>
        )}
      </div>
      <Link to={`/product/${id}`}>
        <div className="px-4 py-2">
          <div className="font-semibold text-xs capitalize text-purple-400 mb-1">
            {category ? category : subcategory}
          </div>
          <div className="font-semibold text-xs capitalize text-orange-600 mb-1">
            {subcategory || null}
          </div>
          <h2 className="font-semibold text-sm mb-1">{name}</h2>
          <div className="font-semibold text-xs pb-1">KES {cost}</div>
          <div className="text-xs text-gray-400 font-semibold">
            {size ? `${size}` : "ml"} | {plevel ? `${plevel}%` : "%"}
          </div>
        </div>
      </Link>
      <div className="flex-grow flex items-center px-4 py-4">
        <button
          onClick={() => addToCart(product, id)}
          className={`px-8 py-2 transition duration-300 ${
            isHovered
              ? "text-white bg-orange-600"
              : "text-orange-600 border border-orange-600"
          }`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default Product;
