import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../contexts/CartContext";
import { ShippingDetailsContext } from "../contexts/ShippingDetailsContext";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/LottieAnimations/orderloading.json";
import successAnimation from "../assets/LottieAnimations/ordersuccess.json";
import errorAnimation from "../assets/LottieAnimations/ordererror.json";

export const ConfirmationStep = ({
  onPrev,
  isPaymentComplete,
  setIsPaymentComplete,
  payRequestSent,
  setPayRequestSent,
}) => {
  const { userID, invoiceNumber } = useContext(AuthContext);
  const { formData, setFormData } = useContext(ShippingDetailsContext);
  const [progress, setProgress] = useState(0);
  const [orderDetails, setOrderDetails] = useState(null);
  const [payErrorMessage, setPayErrorMessage] = useState(null);
  const [payOnDelivery, setPayOnDelivery] = useState(false);

  console.log(
    "payment complete?",
    isPaymentComplete,
    "request sent?",
    payRequestSent,
    "with message",
    payErrorMessage
  );

  const handlePhoneNumberChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePaymentRequest = () => {
    setIsPaymentComplete(false);
    setProgress(30);

    let data = JSON.stringify({
      uid: userID,
      phone: `${254}${formData.phone.slice(-9)}`,
      name: formData.firstName + " " + formData.lastName,
      email: formData.email,
      house: formData.house,
      invoice: invoiceNumber,
      dlocation: formData.location,
      town: formData.deliveryAddress,
      delocation: formData.deliveryAddress,
      paymethod: parseInt(formData.paymethod),
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://webapp.chupachap.co.ke/run/addorder.php",
      // url: "/addorder.php",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
      },
      data: data,
    };

    console.log("Sending order", data);
    setProgress(50);

    axios
      .request(config)
      .then((response) => {
        setProgress(100);
        console.log("Pay response", response.data);
        setPayRequestSent(true);
        if (formData.paymethod === "1" && response.data.code !== "7401") {
          setPayOnDelivery(true);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setOrderDetails({ amount: response.data.amount });
        } else if (response.data.code === "7401") {
          toast.error(response.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          localStorage.removeItem("SessionData");
        } else {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setOrderDetails({ amount: response.data.amount });
        }

        // clear localStorage
      })
      .catch((error) => {
        toast.error(error.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        localStorage.removeItem("SessionData");
      });

    // return () => clearTimeout(checkPayment);
  };

  // useEffect(() => {
  //   console.log(
  //     "effect called",
  //     payRequestSent,
  //     isPaymentComplete,
  //     orderDetails
  //   );

  //   if (orderDetails !== null) {
  //     console.log("were in");
  //     const { amount } = orderDetails;

  //     const interval = setInterval(() => {
  //       // Check payment if request is sent and payment is not complete
  //       if (payRequestSent && !isPaymentComplete) {
  //         console.log("checkPayment called");

  //         let data = JSON.stringify({
  //           uid: userID,
  //           phone: `${254}${formData.phone.slice(-9)}`,
  //           invoice: invoiceNumber,
  //           amount: amount,
  //         });

  //         let config = {
  //           method: "post",
  //           maxBodyLength: Infinity,
  //           url: "/confirmpay.php",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization:
  //               "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
  //           },
  //           data: data,
  //         };

  //         axios
  //           .request(config)
  //           .then((response) => {
  //             console.log("checkPayment response", response.data);
  //             if (response.data.code === "7101") {
  //               console.log("Payment completed");
  //               setIsPaymentComplete(true);
  //             } else {
  //               console.log("Not yet done");
  //               setPayErrorMessage(response.data);
  //               setIsPaymentComplete(false);
  //             }
  //           })
  //           .catch((error) => {
  //             console.log("check error", error);
  //           });
  //       }
  //     }, 5000);

  //     return () => clearInterval(interval); // Cleanup the interval on component unmount or when isPaymentComplete becomes true
  //   }
  // }, [payRequestSent, isPaymentComplete]);
  useEffect(() => {
    console.log(
      "payment complete?",
      isPaymentComplete,
      "request sent?",
      payRequestSent,
      "with message",
      payErrorMessage
    );

    if (orderDetails !== null) {
      console.log("were in");
      const { amount } = orderDetails;

      let callCount = 0; // Initialize the call count variable

      const interval = setInterval(() => {
        // Check payment if request is sent and payment is not complete
        if (payRequestSent && !isPaymentComplete) {
          console.log("checkPayment called");

          let data = JSON.stringify({
            uid: userID,
            phone: `${254}${formData.phone.slice(-9)}`,
            invoice: invoiceNumber,
            amount: amount,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://webapp.chupachap.co.ke/run/confirmpay.php",
            // url: "/confirmpay.php",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              console.log("checkPayment response", response.data);
              if (response.data.code === "7101") {
                console.log("Payment completed");
                setIsPaymentComplete(true);
              } else {
                console.log("Not yet done", callCount);
                callCount++;

                // Check if the effect has been called 12 times (at least a minute)
                if (callCount >= 12) {
                  setPayErrorMessage(response.data);
                  setIsPaymentComplete(false);
                  clearInterval(interval); // Stop the interval
                }
              }
            })
            .catch((error) => {
              console.log("check error", error);
            });
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [payRequestSent, isPaymentComplete]);

  const confirmPayment = () => {
    setIsPaymentComplete(false);
    setPayRequestSent(true);
    setPayErrorMessage(null);
  };

  const completeOrder = () => {
    localStorage.removeItem("SessionData");
    window.location.reload();
  };

  const retryPayment = () => {
    setIsPaymentComplete(false);
    setPayRequestSent(true);
    setPayErrorMessage(null);
    if (orderDetails !== null) {
      const { amount } = orderDetails;

      setProgress(30);
      let data = JSON.stringify({
        uid: userID,
        invoice: invoiceNumber,
        phone: `${254}${formData.phone.slice(-9)}`,
        amount: amount,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://webapp.chupachap.co.ke/run/stkreq.php",
        // url: "/stkreq.php",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
        },
        data: data,
      };
      console.log("sending to retry", data);
      setProgress(50);
      axios
        .request(config)
        .then((response) => {
          setProgress(100);
          console.log("Retry response", response.data);
          setPayRequestSent(true);
          if (response.data) {
            toast.success(response.data, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.error("Request Failed!!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          setProgress(100);
          console.log("Retry error", error.data);
        });
    }
  };

  if (
    payRequestSent &&
    !isPaymentComplete &&
    payErrorMessage === null &&
    !payOnDelivery
  ) {
    return (
      <section className="p-8  flex flex-col justify-center items-center">
        <Lottie
          animationData={loadingAnimation}
          loop
          autoplay
          style={{ width: 250, height: 250 }}
        />
        <p className="text-lg mt-4">Confirming payment...</p>
        <p>Do not reload this page while we confirm your payment</p>
      </section>
    );
  }

  if (payRequestSent && isPaymentComplete) {
    return (
      <section className="p-8 my-12 flex flex-col justify-center items-center">
        <Lottie
          animationData={successAnimation}
          loop={false} // Set loop to false for a one-time play
          autoplay
          style={{ width: 250, height: 250 }}
        />
        <p className="text-lg mt-4">Payment successful! 🎉</p>
        <button
          onClick={completeOrder}
          className="bg-black my-2 text-white py-2 px-4 ">
          COMPLETE ORDER
        </button>
      </section>
    );
  }

  if (payRequestSent && payOnDelivery && orderDetails !== null) {
    return (
      <section className="p-8 my-12 flex flex-col justify-center items-center">
        <Lottie
          animationData={successAnimation}
          loop={false} // Set loop to false for a one-time play
          autoplay
          style={{ width: 250, height: 250 }}
        />
        <p className="text-lg mt-4">Order successful! 🎉</p>
        <p className="text-lg mt-4">
          Pay KES. {orderDetails.amount} for order {invoiceNumber} on Delivery
        </p>
        <button
          onClick={completeOrder}
          className="bg-black my-2 text-white py-2 px-4 ">
          COMPLETE ORDER
        </button>
      </section>
    );
  }

  if (payRequestSent && !isPaymentComplete && payErrorMessage === null) {
    return (
      <section className="p-8 my-12 flex flex-col justify-center items-center">
        <Lottie
          animationData={successAnimation}
          loop={false} // Set loop to false for a one-time play
          autoplay
          style={{ width: 250, height: 250 }}
        />
        <p className="text-lg mt-4">Order successful! 🎉</p>
        <button
          onClick={completeOrder}
          className="bg-black my-2 text-white py-2 px-4 ">
          COMPLETE ORDER
        </button>
      </section>
    );
  }

  if (payRequestSent && !isPaymentComplete && payErrorMessage.code === "7401") {
    return (
      <section className="p-8  flex flex-col justify-center items-center">
        <Lottie
          animationData={errorAnimation}
          loop
          autoplay
          style={{ width: 250, height: 250 }}
        />
        <p className="text-lg my-6 text-center ">{payErrorMessage.error} OR</p>
        <div className="flex gap-6">
          <button
            onClick={retryPayment}
            className="bg-black my-2 text-white py-2 px-4 ">
            RETRY PAYMENT
          </button>
          <button
            onClick={confirmPayment}
            className="bg-orange-600 my-2 text-white py-2 px-4 ">
            CONFIRM PAYMENT
          </button>
        </div>
      </section>
    );
  }

  return (
    <div className="container mx-auto my-8 p-8 bg-white shadow-lg rounded-lg">
      <LoadingBar
        color="blue"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={3}
      />
      <h2 className="text-3xl font-bold mb-6">Order Confirmation</h2>
      {formData.paymethod === "1" ? (
        <div className="mb-4">
          <p>To complete order, follow the steps below:</p>
          <div className="mx-2 px-10">
            <p className="list-item">Confirm Order</p>
            <p className="list-item">Wait for Delivery </p>
            <p className="list-item">Pay for the delivery</p>
          </div>
        </div>
      ) : (
        <div className="mb-4">
          <p>To complete payment, follow the steps below:</p>
          <div className="mx-2 px-10">
            <p className="list-item">
              Check your phone number for the M-PESA PIN prompt.
            </p>
            <p className="list-item">Key in your M-PESA PIN and submit </p>
            <p className="list-item">
              Wait for this page to refresh as we confirm your payment
            </p>
            <p className="list-item">
              Please confirm your phone number below OR change to another
              number:
            </p>
          </div>
          <input
            type="text"
            placeholder="Enter your phone number"
            name="phone"
            value={formData.phone}
            onChange={handlePhoneNumberChange}
            className="border border-gray-400 my-10 p-2 w-1/2 bg-gray-200"
          />
        </div>
      )}
      <div className="lg:flex sm:flex-column gap-4 lg:w-2/3">
        {orderDetails === null ? (
          <button
            onClick={handlePaymentRequest}
            className="bg-orange-600  my-2 text-white py-2 px-4 ">
            {formData.paymethod === "1"
              ? "CONFIRM ORDER"
              : " SEND PAYMENT REQUEST TO PHONE"}
          </button>
        ) : null}
      </div>
      {isPaymentComplete && (
        <div className="mt-4 text-green-600">
          Payment request sent. Check your phone for the M-PESA PIN prompt. Key
          in your M-PESA PIN and submit. Please wait for the page to refresh to
          proceed.
        </div>
      )}
    </div>
  );
};

// import React from "react";

// export const ConfirmationStep = ({ onPrev }) => {
//   return (
//     <div>
//       {/* Confirmation step content */}
//       <div>confirming the order last page</div>
//       <button onClick={onPrev} className="btn-secondary mr-2">
//         Previous
//       </button>
//       <button className="btn-primary">Place Order</button>
//     </div>
//   );
// };
