import React, { createContext, useState } from "react";

export const SectionHeaderContext = createContext();

const SectionHeaderProvider = ({ children }) => {
  const [newArrivalsHeaders, setNewArrivalsHeaders] = useState([
    {
      src: require("../assets/Gin-of-the-Month.png"),
      description: "Champagne",
    },
    {
      src: require("../assets/Wines-Leaderboard-1.png"),
      description: "Red Wine",
    },
    {
      src: require("../assets/Beers-Leaderboard-3.png"),
      description: "Rose",
    },
    {
      src: require("../assets/Beers-Leaderboard-3.png"),
      description: "White Wine",
    },
  ]);

  const [bestDeals, setBestDeals] = useState([
    {
      src: require("../assets/banner-left.png"),
      description: "Gin",
    },
    {
      src: require("../assets/Wines-Leaderboard-1.png"),
      description: "Rum",
    },
    {
      src: require("../assets/Beers-Leaderboard-3.png"),
      description: "Whisky",
    },
    {
      src: require("../assets/Beers-Leaderboard-3.png"),
      description: "Vodka",
    },
  ]);

  const [bestSellingWines, setBestSellingWines] = useState([
    {
      src: require("../assets/Gin-of-the-Month.png"),
      description: "Champagne",
    },
    {
      src: require("../assets/Wines-Leaderboard-1.png"),
      description: "Red Wine",
    },
    {
      src: require("../assets/Beers-Leaderboard-3.png"),
      description: "Rose",
    },
    {
      src: require("../assets/Beers-Leaderboard-3.png"),
      description: "White Wine",
    },
  ]);

  const [bestSellingBeers, setBestSellingBeers] = useState([
    {
      src: require("../assets/banner-left.png"),
      description: "Craft Beer",
    },
    {
      src: require("../assets/Wines-Leaderboard-1.png"),
      description: "Lager",
    },
    {
      src: require("../assets/Beers-Leaderboard-3.png"),
      description: "Pilsner",
    },
    {
      src: require("../assets/banner-left.png"),
      description: "Stout",
    },
  ]);

  return (
    <SectionHeaderContext.Provider
      value={{
        newArrivalsHeaders,
        bestDeals,
        bestSellingWines,
        bestSellingBeers,
      }}>
      {children}
    </SectionHeaderContext.Provider>
  );
};

export default SectionHeaderProvider;
