import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const { userID, invoiceNumber } = useContext(AuthContext);
  const [cart, setCart] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [updatingCart, setUpdatingCart] = useState(false);
  const [changeQuantity, setChangeQuantity] = useState(1);
  const [updatedCartItem, setUpdatedCartItem] = useState(null);

  // console.log("Change quantity", changeQuantity, updatingCart);
  useEffect(() => {
    // Update local storage whenever data changes
    const dataToSave = {
      uid: userID,
      invoiceNumber: invoiceNumber,
      totalAmount: totalAmount,
      totalQuantity: totalQuantity,
      cart: cart,
    };
    if (userID && invoiceNumber && totalAmount && totalQuantity && cart) {
      localStorage.setItem("cartData", JSON.stringify(dataToSave));
    } //TODO:: Fetch cart from api
  }, [cart, totalAmount, totalQuantity, userID, invoiceNumber]);

  useEffect(() => {
    if (invoiceNumber && userID) {
      let data = JSON.stringify({
        uid: userID,
        invoice: invoiceNumber,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://webapp.chupachap.co.ke/run/getcartitem.php",
        // url: "/getcartitem.php",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          setCart(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userID, invoiceNumber, cart]);

  useEffect(() => {
    if (cart && cart.length > 0) {
      const amount = cart.reduce((accumulator, currentItem) => {
        return (
          accumulator + parseInt(currentItem.qty) * parseInt(currentItem.price)
        );
      }, 0);
      setTotalAmount(parseInt(amount));
    }
  }, [cart]);

  useEffect(() => {
    if (cart && cart.length > 0) {
      const quantity = cart.reduce((accumulator, currentItem) => {
        return accumulator + parseInt(currentItem.qty);
      }, 0);
      setTotalQuantity(parseInt(quantity));
    }
  }, [cart]);

  const addToCart = (product, id) => {
    const newItem = { ...product, quantity: 1 };

    saveToApiCart(newItem);
  };

  const saveToApiCart = (cartItem) => {
    setProgress(30);
    let data = JSON.stringify({
      uid: userID,
      qty: cartItem.quantity,
      product: cartItem.id,
      price: cartItem.cost,
      invoice: invoiceNumber,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://webapp.chupachap.co.ke/run/additem.php",
      // url: "/additem.php",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
      },
      data: data,
    };
    setProgress(50);
    axios
      .request(config)
      .then((response) => {
        setProgress(100);
        //TODO: Add success toast
        if (response.data.code === "7401") {
          toast.error(response.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            height: 4,
          });
        } else {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        if (error.data.code === "27401") {
          // increaseQuantity(); TODO:: call update function
          toast.error("🦄 Wow so easy we failed!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const removeFromCart = (product, id) => {
    setProgress(30);
    console.log("Received", product, id);

    let data = JSON.stringify({
      uid: userID,
      invoice: invoiceNumber,
      product: product,
      qty: "0",
      trans: id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://webapp.chupachap.co.ke/run/editcartitem.php",
      // url: "/editcartitem.php",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
      },
      data: data,
    };

    console.log("Sending to edit", data);
    setProgress(50);
    axios
      .request(config)
      .then((response) => {
        setProgress(100);
        if (response.data.code === "7401") {
          toast.error(response.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            height: 4,
          });
        } else {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            height: 4,
          });
        }
      })
      .catch((error) => {
        setProgress(100);
        console.log(error);
      });
  };

  const clearCart = () => {
    setCart([]);
  };

  const increaseQuantity = (product, id) => {
    setChangeQuantity(changeQuantity + 1);
    if (cart && cart.length > 0) {
      const cartItem = cart.find((item) => {
        return item.product === id;
      });

      if (cartItem && cartItem.qty > 0) {
        console.log("Pressed", changeQuantity);
        const newItem = {
          ...cartItem,
          qty: `${parseInt(cartItem.qty) + changeQuantity}`,
        };

        console.log("New Cart increasing", newItem);
        setUpdatingCart(true);
        setUpdatedCartItem(newItem);
      } else {
        setUpdatingCart(false);
      }
    }
  };

  const decreaseQuantity = (product, id) => {
    console.log("Decrease pressed");
    if (changeQuantity && parseInt(changeQuantity) > 1) {
      setChangeQuantity(changeQuantity - 1);
      if (cart && cart.length > 0) {
        const cartItem = cart.find((item) => {
          return item.product === id;
        });

        if (cartItem && parseInt(cartItem.qty) > 0) {
          // const newCart = cart.map((item) => {
          //   if (item.product === id) {
          //     return {
          //       ...item,
          //       qty: `${changeQuantity - parseInt(cartItem.qty)}`,
          //     };
          //   } else {
          //     return item;
          //   }
          // });
          const newItem = {
            ...cartItem,
            qty: `${changeQuantity + parseInt(cartItem.qty)}`,
          };
          // edit cart function
          console.log("New cart decreased ", newItem);
          setUpdatingCart(true);
          setUpdatedCartItem(newItem);
        } else {
          console.log("Check failed");
          setUpdatingCart(false);
        }
      }
    }
  };

  const editCart = () => {
    console.log("Editting the cart", updatedCartItem);
    setProgress(30);
    const { id, product } = updatedCartItem;
    if (updatedCartItem) {
      let data = JSON.stringify({
        uid: userID,
        invoice: invoiceNumber,
        product: product,
        qty: changeQuantity,
        trans: id,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://webapp.chupachap.co.ke/run/editcartitem.php",
        // url: "/editcartitem.php",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
        },
        data: data,
      };

      console.log("Sending to edit", data);
      setProgress(50);
      axios
        .request(config)
        .then((response) => {
          setProgress(100);
          if (response.data.code === "7401") {
            toast.error(response.data.error, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              height: 4,
            });
          } else {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              height: 4,
            });
          }
        })
        .catch((error) => {
          setProgress(100);
          console.log(error);
        });
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        increaseQuantity,
        decreaseQuantity,
        totalQuantity,
        totalAmount,
        progress,
        setProgress,
        changeQuantity,
        setChangeQuantity,
        updatingCart,
        setUpdatingCart,
        editCart,
      }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
