import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { SidebarContext } from "../contexts/SidebarContext";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegistrationForm";

const AuthModal = () => {
  const { isAuthOpen, handleAuthClose } =
    useContext(AuthContext);
  // const { isOpen, handleClose, setIsOpen } = useContext(SidebarContext);

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isAuthOpen ? "flex" : "hidden"
      }`}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div
        className="bg-white p-8 rounded shadow-lg"
        style={{ margin: "20vh auto", maxWidth: "80vw" }}
      >
        <div className="absolute top-2 right-2">
          <button
            className="text-black font-bold text-3xl hover:text-gray-800 bg-white p-2"
            onClick={() => handleAuthClose()}
          >
            X
          </button>
        </div>
        <div className="lg:flex sm:flex-column items-center space-y-4 overflow-y-auto">
          {/* Add the overflow-y-auto class here */}
          <LoginForm />
          <RegisterForm />
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
