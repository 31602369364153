import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { IoMdArrowForward } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";

import CartItem from "./CartItem";
import { SidebarContext } from "../contexts/SidebarContext";
import { CartContext } from "../contexts/CartContext";

const Sidebar = () => {
  const { isOpen, handleClose } = useContext(SidebarContext);
  const { cart, clearCart, totalQuantity, totalAmount } =
    useContext(CartContext);

  return (
    <div
      className={`${
        isOpen ? "right-0" : "-right-full"
      } w-full bg-white fixed top-0 h-full shadow-2xl md:w-[35vw] xl:max-w-[30vw] transition-all duration-300 z-20 px-4 lg:px-[35px]" `}>
      <div
        onClick={handleClose}
        className="cursor-pointer flex items-center justify-between py-6 border-b">
        <div className="uppercase text-sm font-semibold">
          Shopping Cart ({totalQuantity})
        </div>
        <div className="cursor-pointer w-8 h-8 flex justify-center items-center">
          <IoMdArrowForward className="text-2xl" />
        </div>
      </div>
      {cart && cart.length > 0 ? (
        <div className="flex flex-col gap-y-2 h-[330px] lg:h-[300px] overflow-y-auto overflow-x-hidden border-b">
          {/* if height doenst work on bigger screens swith to 520px for small and 640px for larger */}
          {cart.map((item) => (
            <CartItem cartItem={item} key={item.id} />
          ))}
        </div>
      ) : null}
      <div className="flex flex-col gap-y-3 py-4 mt-4">
        <div className="flex w-full justify-between items-center">
          <div className="uppercase font-semibold">
            <span className="mr-2">Total: </span> KES{" "}
            {parseFloat(totalAmount).toFixed(2)}
          </div>
          <div
            onClick={clearCart}
            className="cursor-pointer py-4 bg-orange-700 text-white w-12 h-12 flex justify-center items-center text-xl">
            <FiTrash2 />
          </div>
        </div>
        <Link
          to={"/Checkout"}
          onClick={handleClose}
          className="bg-orange-600 py-4 px-8 text-white items-center justify-center flex">
          Checkout
        </Link>
        {/* <button
          // onClick={}
          className="bg-primary py-4 px-8 text-white">
          Checkout
        </button> */}
      </div>
    </div>
  );
};

export default Sidebar;
