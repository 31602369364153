import React, { useContext } from "react";
import { IoMdAdd, IoMdClose, IoMdRemove } from "react-icons/io";
import { Link } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { FiTrash2 } from "react-icons/fi";
import LoadingBar from "react-top-loading-bar";

const CartItemFull = ({ cartItem }) => {
  const { id, gen_name, photo, price, qty, product } = cartItem;
  const {
    removeFromCart,
    increaseQuantity,
    decreaseQuantity,
    progress,
    setProgress,
  } = useContext(CartContext);

  // console.log("Product id", product);

  return (
    <div className="flex gap-x-4 py-2 lg:px-6 border-b border-gray-200 w-full font-light text-gray-500">
      <LoadingBar
        color="blue"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={3}
      />
      <div className="w-full min-h-[150px] flex items-center gap-x-4">
        <Link to={`/product/${product}`}>
          <img className="max-w-[80px]" src={photo} alt="" />
        </Link>
        <div className="flex-col justify-between w-full ">
          <div className="flex justify-between mb-2">
            <Link
              to={`/product/${product}`}
              className="text-sm uppercase font-medium max-w-[240px] text-primary ">
              {gen_name}
            </Link>
            <div
              onClick={() => removeFromCart(product, id)}
              className="cursor-pointer text-xl">
              <IoMdClose className="text-gray-500 hover:text-orange-700 transition" />
            </div>
          </div>
          <div className="flex gap-x-2 h-[36px] text-sm">
            <div className="flex flex-1 max-w-[100px] items-center h-full border text-primary font-medium">
              <div className="flex-1 flex justify-center items-center cursor-pointer">
                Quantity:
              </div>
              <div className="h-full flex justify-center items-center px-2 font-bold">
                {qty}
              </div>
            </div>
            <div className="flex-1 flex items-center justify-around">
              X {price}
            </div>
            <div className="flex-1 flex justify-end items-center text-primary font-medium">
              KES. {parseFloat(price * qty).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItemFull;
