import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductContext } from "../contexts/ProductContext";
import ProductPageBanner from "../components/ProductPageBanner";
import Product from "../components/Product";
import Banner from "../components/Banner";
import { BannerContext } from "../contexts/BannerContext";
import { FilterContext } from "../contexts/FilterContext";
import FilterBar from "../components/FilterBar";
import { BsSliders } from "react-icons/bs";
import Pagination from "../components/Pagination";
import Lottie from "lottie-react";
import loadingAnimation from "../../src/assets/LottieAnimations/loading.json";
import ErrorScreen from "./404Screen";

// TODO: so fo this productcategory/beers its something similar to product/id in that we go to produccategories and assume like we are displaying all products imaginary (from the dropdown menu) so its like we list products in a dropdown the on click we go to filtered product. this will allow me to reuse compnents (Check out videos on product categories) then click on beers so beers acts like some kind of filter s that we only see beers simialr to what we have with product details in that we only see the product details so hoping the data from api will allow us to easily apply filters so that the beer component
// when in the new page we now receve the product category, we can now go ahead and pass the subcategory to api request and then get the subcategory like we do with isOpen, handleclose etc

function ProductsPage() {
  const { category, subcategory } = useParams();
  // const category = null;
  // const subcategory = null;
  //get products fom product context
  const {
    isOpenFilter,
    setIsOpenFilter,
    filters,
    setFilters,
    handleCloseFilter,
  } = useContext(FilterContext);
  const { products, progress } = useContext(ProductContext); //TODO:: WE WONT E NEEDING TO FILER IN PRODUCT CONTEXT
  const { banners } = useContext(BannerContext);
  const [sortMethod, setSortMethod] = useState("popularity");
  const [pageProducts, setPageProducts] = useState([]);

  console.log("Products fetched", products);
  // useEffect(() => {
  //   // Filter products based on category and subcategory
  //   console.log("using", category, subcategory);
  //   const filtered =
  //     category && subcategory
  //       ? products.filter(
  //           (product) =>
  //             product.category.toLowerCase() === category &&
  //             product.subcategory.toLowerCase() === subcategory
  //         )
  //       : category
  //       ? products.filter(
  //           (product) => product.category.toLowerCase() === category
  //         )
  //       : [];

  //   console.log("filtered", filtered);

  //   setPageProducts(filtered);
  // }, [category, subcategory, products]);
  useEffect(() => {
    // Filter products based on category and subcategory
    console.log("using", category, subcategory);
    const filtered =
      category && subcategory
        ? products.filter(
            (product) =>
              product.category &&
              product.subcategory &&
              product.category.toLowerCase() === category &&
              product.subcategory.toLowerCase() === subcategory
          )
        : category
        ? products.filter(
            (product) =>
              product.category && product.category.toLowerCase() === category
          )
        : [];

    console.log("filtered", filtered);

    setPageProducts(filtered);
  }, [category, subcategory, products]);

  console.log("filtered products", pageProducts);

  // useEffect(() => {
  //   // TODO:: Filter products by category first
  //   // Fetch products based on category and subcategory
  //   const filterProducts = () => {
  //     // Filter products based on category and subcategory

  //     // Apply filters
  //     let filteredProducts = products.filter((product) => {
  //       // console.log("passed cats", product.category, product.subcategory);
  //       return product.subcategory === subcategory;
  //       // Check if product matches filter criteria
  //       // For example, check if product price is within the selected price range
  //       // and if product category or tags match selected filters
  //     });
  //     console.log("filtered products", filteredProducts, category, subcategory);

  //     // Update state with filtered products
  //     // ...
  //   };

  //   return filterProducts();
  // }, [category, subcategory]);

  const [currentPageNumber, setCurrentPageNumber] = useState(parseInt(1));
  const productsPerPage = 16;

  // Calculate startIndex and endIndex based on the currentPageNumber
  const startIndex = (currentPageNumber - 1) * productsPerPage;
  const endIndex = startIndex + productsPerPage;

  // Render pagination buttons
  const totalPages = Math.ceil(pageProducts.length / productsPerPage);
  const paginationButtons = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationButtons.push(
      <button
        key={i}
        onClick={() => setCurrentPageNumber(i)}
        className={currentPageNumber === i ? "active" : ""}>
        {i}
      </button>
    );
  }

  const sortProducts = (pageProducts, sortMethod) => {
    let sortedProducts = [...pageProducts];

    if (sortMethod === "popularity") {
      // sortedProducts.sort((a, b) => b.rating.count - a.rating.count);
    } else if (sortMethod === "priceHighToLow") {
      sortedProducts.sort((a, b) => b.cost - a.cost);
    } else if (sortMethod === "priceLowToHigh") {
      sortedProducts.sort((a, b) => a.cost - b.cost);
    } else if (sortMethod === "latest") {
      sortedProducts.sort((a, b) => b.id - a.id);
    }

    return sortedProducts;
  };

  const sortedProducts = sortProducts(pageProducts, sortMethod);

  // Get products for the current page
  const currentProducts = sortedProducts.slice(startIndex, endIndex);

  console.log("progress before", progress, products);
  if (pageProducts.length < 0) {
    console.log("progress", progress, products);
    return (
      <section className="h-screen flex justify-center items-center">
        <Lottie
          animationData={loadingAnimation}
          loop={false}
          autoplay
          style={{ width: 150, height: 150 }}
        />
        <div>Loading...</div>
      </section>
    );
  } else if (pageProducts && pageProducts.length === 0) {
    return <ErrorScreen />;
  }

  return (
    <div>
      <ProductPageBanner category={category} />
      <div className=" pt-10 container mx-auto">
        <div className="flex justify-between ">
          <div className="font-bold text-black uppercase">{`${category || ""}/${
            subcategory || ""
          }`}</div>
          <div className="flex ">
            {/* TODO:: change these values to represnet products.length sliced and total  */}
            {/* <div className="pr-10 px-5 py-2">
              Showing {`${startIndex}-${endIndex}`} of{" "}
              {products.length} results
            </div> */}
            <div className="border border-[#e4e4e4] px-5 py-2">
              Sort by:
              <select
                className="ml-2"
                onChange={(e) => setSortMethod(e.target.value)}
                value={sortMethod}>
                <option value="popularity">Popularity</option>
                <option value="priceHighToLow">Price: High to Low</option>
                <option value="priceLowToHigh">Price: Low to High</option>
                <option value="latest">Latest</option>
              </select>
            </div>
          </div>
        </div>
        <div
          className="cursor-pointer flex gap-4 w-1/4"
          onClick={() => setIsOpenFilter(!isOpenFilter)}>
          <BsSliders className="text-black text-lg" />
          Filter
        </div>
      </div>
      <div className="py-12 flex container mx-auto">
        {isOpenFilter && <FilterBar category={category} />}
        <div
          className={`${
            isOpenFilter ? "w-full" : "container w-full"
          } w-full bg-white  top-0 h-full transition-all duration-300 px-4 mx-auto`}>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4 ">
            {/* TODO:: Check to see responsiveness and if we can return these max-w-sm mx-auto md:max-w-none md:mx-0 */}
            {currentProducts.length > 0 &&
              currentProducts.map((product) => {
                return <Product product={product} key={product.id} />;
              })}
          </div>
        </div>
        {/* TODO:: Implement pagination */}
      </div>
      <Pagination
        currentPage={currentPageNumber}
        totalPages={Math.ceil(sortedProducts.length / productsPerPage)}
        onPageChange={(page) => setCurrentPageNumber(page)}
      />
      <Banner image={banners[1].image} />
    </div>
  );
}

export default ProductsPage;
