import React, { useContext, useState } from "react";
import Stepper from "../components/Stepper";
import { CartStep } from "./CartStep";
import { ShippingPaymentStep } from "./ShippingPaymentStep";
import { ConfirmationStep } from "./ConfirmationStep";
import { CartContext } from "../contexts/CartContext";
import { ShippingDetailsContext } from "../contexts/ShippingDetailsContext";

const Checkout = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [payRequestSent, setPayRequestSent] = useState(false);
  const { cart } = useContext(CartContext);
  const { formData } = useContext(ShippingDetailsContext);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="container mx-auto mt-8">
      <Stepper
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isPaymentComplete={isPaymentComplete}
        setIsPaymentComplete={setIsPaymentComplete}
        payRequestSent={payRequestSent}
        setPayRequestSent={setPayRequestSent}
        cart={cart}
        formData={formData}
      />
      {/* Content for each step */}
      {currentStep === 0 && <CartStep onNext={nextStep} />}
      {currentStep === 1 && (
        <ShippingPaymentStep onNext={nextStep} onPrev={prevStep} />
      )}
      {currentStep === 2 && (
        <ConfirmationStep
          onPrev={prevStep}
          isPaymentComplete={isPaymentComplete}
          setIsPaymentComplete={setIsPaymentComplete}
          payRequestSent={payRequestSent}
          setPayRequestSent={setPayRequestSent}
        />
      )}
    </div>
  );
};

export default Checkout;
