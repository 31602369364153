import React, { useContext } from "react";
import LoadingBar from "react-top-loading-bar";
import { Link } from "react-router-dom";

//IMPORT PRODUCT CONTEXT
import { ProductContext } from "../contexts/ProductContext";
import Hero from "../components/Hero";
import Product from "../components/Product";
import ThreeGridAds from "../components/ThreeGridAds";
import Banner from "../components/Banner";

//import images
import { SectionHeaderContext } from "../contexts/SectionHeaderContext";
import { BannerContext } from "../contexts/BannerContext";
import { ThreeAdsGridContext } from "../contexts/ThreeGridAdsContext";
import { DoubleGridAdsContext } from "../contexts/DoubleGridAdsContext";
import Lottie from "lottie-react";
import loadingAnimation from "../../src/assets/LottieAnimations/loading.json";

const Home = () => {
  //get products fom product context
  const { products, progress, setProgress } = useContext(ProductContext);
  const { newArrivalsHeaders, bestDeals, bestSellingWines, bestSellingBeers } =
    useContext(SectionHeaderContext);
  const { banners, bestBanners } = useContext(BannerContext);
  const { gridbanners } = useContext(ThreeAdsGridContext);
  const { dgridbanners } = useContext(DoubleGridAdsContext);
  // const newArrivalsHeaders = SectionHeaderContext()

  console.log("products fetched", products);

  //TODO:: we can then apply filters using  to the products to get diffenet product types

  if (products.length < 0) {
    return (
      <section className="h-screen flex justify-center items-center">
        <Lottie
          animationData={loadingAnimation}
          loop={false} // Set loop to false for a one-time play
          autoplay
          style={{ width: 150, height: 150 }}
        />
      </section>
    );
  }

  return (
    <div className="bg-gray-200">
      <LoadingBar
        color="blue"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={3}
      />
      {/* hero section */}
      <Hero />

      {/* Best deals */}
      <section className="py-10 bg-white mx-4 my-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4 ">
            <div>
              <h2 className="font-black text-3xl">Best Deals</h2>
              <div className="font-semibold text-xs text-gray-400">
                Discover our best deals in Gin, Whisky, Rum and Vodka
              </div>
            </div>
            {bestDeals.map((image, index) => (
              <Link
                to={`/product-category/spirits/${image.description.toLowerCase()}`}
                key={index}
                className="relative group">
                <img
                  src={image.src}
                  alt={image.description}
                  className="w-full h-24 object-cover hover:scale-110 transition-transform transform"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-blue-100 bg-opacity-70">
                  <p className="text-black text-lg font-bold p-4 rounded">
                    {image.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
          <div className="underline cursor-pointer text-orange-600 font-bold py-10">
            VIEW ALL
          </div>
        </div>
        <div className="py-12">
          <div className="container mx-auto">
            {products.length > 0 ? (
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4 mx-auto ">
                {products
                  .filter(
                    (product) =>
                      (product.tags && product.tags.includes("best-deals")) ||
                      product.tags.includes("best-deal")
                  )
                  .slice(0, 5)
                  .map((product) => {
                    return <Product product={product} key={product.id} />;
                  })}
              </div>
            ) : null}
          </div>
        </div>
      </section>

      {/* banner section */}

      {banners && banners.length > 0 && <Banner image={banners[0].photo} />}
      {/* New Arrivals */}
      <section className="py-16 bg-white mx-4 my-4 ">
        <div className="container mx-auto">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4 ">
            <div>
              <h2 className="font-black text-3xl">New Arrivals</h2>
              <div className="font-semibold text-xs text-gray-400">
                Discover the latest arrivals
              </div>
            </div>
            {newArrivalsHeaders.map((image, index) => (
              <Link
                to={`/product-category/wine/${image.description.toLowerCase()}`}
                key={index}
                className="relative group">
                <img
                  src={image.src}
                  alt={image.description}
                  className="w-full h-24 object-cover hover:scale-110 transition-transform transform"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-blue-100 bg-opacity-70">
                  <p className="text-black text-lg font-bold p-4 rounded">
                    {image.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
          <div className="underline cursor-pointer text-orange-600 font-bold py-10">
            VIEW ALL
          </div>
        </div>
        <div className="py-16">
          <div className="container mx-auto">
            {products.length > 0 ? (
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4">
                {products
                  .filter(
                    (product) =>
                      product.tags && product.tags.includes("new-arrivals")
                  )
                  .slice(0, 5)
                  .map((product) => {
                    return <Product product={product} key={product.id} />;
                  })}
              </div>
            ) : null}
          </div>
        </div>
      </section>
      {/* grid banners */}
      <section className="py-6">
        <div className="container mx-auto">
          <div
            className={
              "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6 "
            }>
            {gridbanners &&
              gridbanners.length > 0 &&
              gridbanners.map((image, index) => (
                <Link
                  // to={`/product-category/${image.description.toLowerCase()}`}
                  key={index}
                  className="relative group">
                  <img
                    src={image.photo}
                    alt={image.id}
                    className="object-cover"
                  />
                </Link>
              ))}
          </div>
        </div>
      </section>
      {/* Best Selling wines*/}
      <section className="py-10 bg-white mx-4 my-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4 ">
            <div>
              <h2 className="font-black text-3xl">Best Selling Wines</h2>
              <div className="font-semibold text-xs text-gray-400">
                Discover our wide range of Champagne, Red Wine, Rose and White
                Wine
              </div>
            </div>
            {bestSellingWines.map((image, index) => (
              <Link
                to={`/product-category/spirits/${image.description.toLowerCase()}`}
                key={index}
                className="relative group">
                <img
                  src={image.src}
                  alt={image.description}
                  className="w-full h-24 object-cover hover:scale-110 transition-transform transform"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-blue-100 bg-opacity-70">
                  <p className="text-black text-lg font-bold p-4 rounded">
                    {image.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
          <div className="underline cursor-pointer text-orange-600 font-bold py-10">
            VIEW ALL
          </div>
        </div>
        <div className="py-12">
          <div className="container mx-auto">
            {products.length > 0 ? (
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4 ">
                {products
                  .filter(
                    (product) =>
                      product.tags &&
                      product.tags.includes("best-selling-wines")
                  )
                  .slice(0, 5)
                  .map((product) => {
                    return <Product product={product} key={product.id} />;
                  })}
              </div>
            ) : null}
          </div>
        </div>
      </section>

      {/* DoubleGrid ads */}
      <section className="py-6">
        <div className="container mx-auto">
          <div
            className={
              "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2  md:gap-6"
            }>
            {dgridbanners &&
              dgridbanners.length > 0 &&
              dgridbanners.map((image, index) => (
                <Link
                  // to={`/product-category/${image.description.toLowerCase()}`}
                  key={index}
                  className="relative group">
                  <img
                    src={image.photo}
                    alt={image.id}
                    className="object-cover"
                  />
                </Link>
              ))}
          </div>
        </div>
      </section>

      {/* Best selling Beers */}
      <section className="py-10 bg-white mx-4 my-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4 ">
            <div>
              <h2 className="font-black text-3xl">Best Selling Beers</h2>
              <div className="font-semibold text-xs text-gray-400">
                Discover our wide range of craft beers, lagers, pilsners and
                stouts
              </div>
            </div>
            {bestSellingBeers.map((image, index) => (
              <Link
                to={`/product-category/beer/${image.description.toLowerCase()}`}
                key={index}
                className="relative group">
                <img
                  src={image.src}
                  alt={image.description}
                  className="w-full h-24 object-cover hover:scale-110 transition-transform transform"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-blue-100 bg-opacity-70">
                  <p className="text-black text-lg font-bold p-4 rounded">
                    {image.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
          <div className="underline cursor-pointer text-orange-600 font-bold py-10">
            VIEW ALL
          </div>
        </div>
        <div className="py-12">
          <div className="container mx-auto">
            {products.length > 0 ? (
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4 ">
                {products
                  .filter(
                    (product) =>
                      product.tags &&
                      product.tags.includes("best-selling-beers")
                  )
                  .slice(0, 5)
                  .map((product) => {
                    return <Product product={product} key={product.id} />;
                  })}
              </div>
            ) : null}
          </div>
        </div>
      </section>
      {/* Banner section */}
      {banners && banners.length > 1 && <Banner image={banners[1].photo} />}
    </div>
  );
};

export default Home;
