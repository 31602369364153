import React from "react";

function Banner({ image }) {
  return (
    <div className="flex items-center justify-center w-full py-6">
      <img className="object-scale-down h-48 col-span-5" src={image} />
    </div>
  );
}

export default Banner;
