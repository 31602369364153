import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { encode } from "base-64";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthOpen, setIsAuthOpen] = useState(false);
  const [ipAddress, setIpAddress] = useState(0);
  const [userID, setUserID] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(null);

  console.log("Working with", userID, invoiceNumber);
  //fetch IP using IP data API
  useEffect(() => {
    localStorage.removeItem("SessionData");
    fetch(
      "https://api.ipdata.co?api-key=de7257ef9c1e145d6016ef323a049ad636229b78ec1474a5ec850603" //TODO:: Change the API key to a company issued one incase there is billing
    )
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        setIpAddress(res.ip);
        let UserIP = res.ip;
        //check local if session started
        const savedData = localStorage.getItem("SessionData");

        if (savedData) {
          const { invoice, uid } = JSON.parse(savedData);
          if (uid) {
            //pass to context API
            setInvoiceNumber(invoice);
            setUserID(uid);

            //check if online or expired
            checkSession(uid, UserIP);
          } else {
            startSession(UserIP);
          }
        } else {
          console.log("Session not existing set new session");
          // set user online presence
          startSession(UserIP);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleAuthClose = () => {
    setIsAuthOpen(false);
  };

  function startSession(UserIP) {
    console.log("start session called", UserIP);
    let data = JSON.stringify({
      ip: UserIP,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://webapp.chupachap.co.ke/run/startonline.php",
      // url: "/startonline.php",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const { uid, invoice, message } = response.data;
        //set to context API
        setInvoiceNumber(invoice);
        setUserID(uid);

        // Save the API response to localStorage
        localStorage.setItem(
          "SessionData",
          JSON.stringify({
            invoice: invoice,
            uid: uid,
            message: message,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function checkSession(UserID, UserIP) {
    console.log("check session called", UserID, UserIP);

    let data = JSON.stringify({
      uid: UserID,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://webapp.chupachap.co.ke/run/checkonline.php",
      // url: "/checkonline.php",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic Y1lGREd3cWVaMnZ2djMxMUQ6R3V0ZXJTZXJ2ZTIyZTdmMjA2NzMxODczREVFRTIzd3M0PzU4NTg1ZA==",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        //Check if expired session
        const { is_expired } = response.data;
        if (is_expired === "yes") {
          startSession(UserIP);
        } else {
          //Session is active do nothing
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthOpen,
        setIsAuthOpen,
        handleAuthClose,
        userID,
        invoiceNumber,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
