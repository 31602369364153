import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductContext } from "../contexts/ProductContext";
import { CartContext } from "../contexts/CartContext";
import Product from "../components/Product";
import { ToastContainer } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

const ProductDetails = () => {
  const { id } = useParams();
  const { products } = useContext(ProductContext);
  const {
    addToCart,
    cart,
    increaseQuantity,
    decreaseQuantity,
    progress,
    setProgress,
    changeQuantity,
    setChangeQuantity,
    updatingCart,
    setUpdatingCart,
    editCart,
  } = useContext(CartContext);
  const [quantity, setQuantity] = useState(0);
  const [hasFetched, setHasFetched] = useState(false);
  const [disableButtons, setdisableButtons] = useState(updatingCart);

  useEffect(() => {
    if (cart && cart.length > 0 && !hasFetched) {
      const cartItem = cart.find((item) => item.product === id);

      if (cartItem) {
        console.log("Cart item in product", cartItem);
        setQuantity(cartItem.qty);
        setChangeQuantity(parseInt(cartItem.qty));
      } else {
        setQuantity(1);
      }

      // Update the state to indicate that data has been fetched
      setHasFetched(true);
    }
  }, [cart, id, hasFetched, setChangeQuantity]);

  const product = products.find((item) => item.id === id);

  if (!product) {
    return (
      <section className="h-screen flex justify-center items-center">
        {/* <LoadingBar
          color="#f11946"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        /> */}
        Loading...
      </section>
    );
  }

  const { name, cost, photo, category, description, subcategory } = product;
  

  return (
    <section className="pt-32 pb-12 lg:py-32 min-h-screen">
      <LoadingBar
        color="blue"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={3}
      />
      <div className="container mx-auto flex items-center flex-col lg:flex-row">
        <div className="flex-1 flex justify-center items-center mb-8 lg:mb-0">
          <img
            className="w-[200px] md:max-w-[200px] lg:max-w-sm"
            src={photo}
            alt=""
          />
        </div>
        <div className="flex-1 text-center lg:text-left">
          <div className="text-gray-400 font-thin uppercase mb-2">{`${category} / ${subcategory}`}</div>
          <h1 className="text-3xl font-bold mb-2 max-w-md mx-auto lg:mx-0">
            {name}
          </h1>
          <div className="text-xl text-orange-600 font-semibold mb-6">
            KES {cost}
          </div>
          <p className="mb-8">{description}</p>
          <div className="flex items-center gap-4">
            <div className="w-52 flex items-center gap-4 border p-3">
              <p className="text-sm">Quantity</p>
              <div className="flex items-center gap-4 text-sm font-semibold self-center">
                <button
                  disabled={disableButtons}
                  onClick={() => decreaseQuantity(product, product.id)}
                  className="border h-5 font-normal text-lg flex items-center justify-center px-2 hover:bg-gray-700 hover:text-white cursor-pointer duration-300 active:bg-black">
                  -
                </button>
                <div>{changeQuantity}</div>
                <button
                  disabled={disableButtons}
                  onClick={() => increaseQuantity(product, product.id)}
                  className="border h-5 font-normal text-lg flex items-center justify-center px-2 hover:bg-gray-700 hover:text-white cursor-pointer duration-300 active:bg-black">
                  +
                </button>
              </div>
            </div>
            {!updatingCart ? (
              <button
                onClick={() => addToCart(product, product.id)}
                className="bg-orange-600 hover:bg-orange-800 text-white py-3 px-6 active:bg-gray-800 rounded-md">
                Add to Cart
              </button>
            ) : (
              <button
                onClick={() => editCart(product, product.id)}
                className="bg-orange-600 hover:bg-orange-800 text-white py-3 px-6 active:bg-gray-800 rounded-md">
                Edit Cart
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="p-4 py-8 items-center mx-auto mt-10 px-16">
        <div className="font-bold mb-2 text-lg">RELATED PRODUCTS</div>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4">
          {products.length > 0 &&
            products
              .filter((product) => ("" ? product.category === "" : true))
              .sort(() => 0.5 - Math.random())
              .slice(0, 5)
              .map((product) => <Product product={product} key={product.id} />)}
        </div>
      </div>
    </section>
  );
};

export default ProductDetails;
