import { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { BsCart, BsCartXFill, BsChevronDown } from "react-icons/bs";
import { SidebarContext } from "../contexts/SidebarContext";
import { CartContext } from "../contexts/CartContext";
import { AuthContext } from "../contexts/AuthContext";
import { Link, useLocation } from "react-router-dom";
import user from "../assets/dummyp.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const { isOpen, setIsOpen } = useContext(SidebarContext);
  const { isAuthOpen, setIsAuthOpen, handleAuthClose } =
    useContext(AuthContext);
  const { totalQuantity, totalAmount } = useContext(CartContext);
  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const [expandedDropdown, setExpandedDropdown] = useState(null);
  const location = useLocation();

  const [navigation, setNavigation] = useState([
    { name: "Beers", href: "/beers", current: true, subcategories: [] },
    {
      name: "Wines",
      href: "/wines",
      current: false,
      subcategories: ["Red Wine", "Champagne", "White Wine", "Rose"],
    },
    {
      name: "Spirits",
      href: "/spirits",
      current: false,
      subcategories: ["Whisky", "Vodka", "Gin", "Brandy & Cognac", "Rum"],
    },
    {
      name: "More",
      href: "/more",
      current: false,
      subcategories: [
        {
          section: "Mixers",
          items: ["Soda", "Tonic", "Juice", "Water"],
        },
        {
          section: "Convenience Store",
          items: [
            "Gifts & Games",
            "Snacks",
            "Smokes",
            "Condoms & Lubes",
            "Convenience Items",
          ],
        },
      ],
    },
  ]);

  useEffect(() => {
    // Update the 'current' property based on the current route
    const updatedNavigation = navigation.map((item) => ({
      ...item,
      current: location.pathname
        .toLowerCase()
        .includes(item.href.toLowerCase()),
    }));

    // Update the state with the new navigation
    setNavigation(updatedNavigation);
  }, [location.pathname]);

  const handleDropdownClick = (itemName, event) => {
    event.stopPropagation(); // Prevent event from propagating to parent elements
    setExpandedDropdown((prevExpanded) =>
      prevExpanded === itemName ? null : itemName
    );
  };

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open, close }) => (
        <>
          <div>
            <div className="bg-orange-600 flex h-10 text-white font-semibold text-sm cursor-pointer justify-center items-center w-full ">
              CALL US +254 717 215 155
            </div>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 border-b border-gray-300 ">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start ">
                  <Link
                    to={"/"}
                    className="flex flex-shrink-0 items-center"
                    onClick={close}>
                    <img
                      className="h-8 w-auto"
                      src="https://i0.wp.com/chupachap.co.ke/wp-content/uploads/2020/02/logo.png?fit=250%2C43&ssl=1"
                      alt="Chupachap"
                    />
                  </Link>
                  <div className="hidden sm:ml-6 sm:block sticky top-0 z-10">
                    <div className="flex space-x-2">
                      {navigation.map((item) => (
                        <div key={item.name} className="relative group">
                          <div className="flex justify-center items-center gap-0">
                            <Link
                              to={`/product-category/${item.name.toLowerCase()}`}
                              onMouseEnter={() =>
                                setSelectedDropdown(item.name)
                              }
                              className={classNames(
                                item.current
                                  ? "text-black underline-offset-8 underline"
                                  : "hover:text-black hover:underline hover:underline-offset-8 text-black",
                                "rounded-md px-2 py-2 text-l font-semibold decoration-2 uppercase"
                              )}
                              aria-current={item.current ? "page" : undefined}>
                              {item.name}
                            </Link>
                            {item.subcategories &&
                              item.subcategories.length > 0 && (
                                <BsChevronDown className="font-semibold text-xs" />
                              )}
                          </div>
                          {selectedDropdown === item.name &&
                            item.subcategories &&
                            item.subcategories.length > 0 && (
                              <div
                                onMouseLeave={() => setSelectedDropdown(null)}
                                className={`absolute top-full left-0 transform bg-white shadow-lg py-2 px-4 space-y-2 my-4 w-60 ${
                                  item.name !== "More" ? "w-60" : "min-w-fit"
                                }`}>
                                {item.name !== "More"
                                  ? item.subcategories.map((subcategory) => (
                                      <Link
                                        to={`/product-category/${item.name.toLowerCase()}/${subcategory.toLowerCase()}`}
                                        key={subcategory}
                                        className="border-b border-gray-200 py-2 cursor-pointer block">
                                        {subcategory}
                                      </Link>
                                    ))
                                  : item.subcategories.map((section) => (
                                      <div key={section.section}>
                                        <p className="font-semibold py-2 whitespace-nowrap">
                                          {section.section}
                                        </p>
                                        {section.items &&
                                          section.items.map((subcategory) => (
                                            <Link
                                              key={subcategory}
                                              to={`/product-category/${item.name.toLowerCase()}/${subcategory.toLowerCase()}`}
                                              className="border-b border-gray-200 py-2 cursor-pointer block">
                                              {subcategory}
                                            </Link>
                                          ))}
                                      </div>
                                    ))}
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <input className="bg-gray-200 "/> */}
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-4">
                  {/* TODO:: Sort login button for smaller screens <div className="hidden sm:block md:block">
                    <div
                      onClick={() => setIsAuthOpen(!isAuthOpen)}
                      className="relative group">
                      <div className="flex justify-center items-center gap-0">
                        <button className=" text-black hover:underline hover:underline-offset-8  rounded-md px-2 py-2 text-l font-semibold decoration-2 uppercase">
                          Login/Register
                        </button>
                      </div>
                    </div>
                  </div>*/}

                  <div className="relative hidden sm:block">
                    <button
                      onClick={() => setIsOpen(!isOpen)}
                      className="bg-orange-600 text-white py-2 px-4">
                      <p className="text-sm">KShs. {totalAmount}</p>
                      {totalQuantity > 0 && (
                        <span className="absolute -top-4 -right-2 bg-black text-white p-1 rounded-full h-6 w-6 text-xs items-center ">
                          {totalQuantity}
                        </span>
                      )}
                    </button>
                  </div>
                </div>
                {/* Profile dropdown */}
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 sm:hidden">
                  {/* TODO:: Fix profile, login, etc dropdown after user has logged in to display either login or signout */}
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    type="button"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BsCart className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>

                  <Menu as="div" className="relative ml-3 ">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}>
                              Your Profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}>
                              Settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}>
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          {/* TODO:: Work out the dropdown menu for mobile phones */}
          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <div key={item.name} className="relative group">
                  <div
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-black hover:bg-gray-700 hover:text-white",
                      "flex rounded-md px-3 py-2 text-base font-medium text-left w-full "
                    )}>
                    <Link
                      to={`/product-category/${item.name.toLowerCase()}`}
                      onClick={close}
                      className="  w-10/12 "
                      aria-current={item.current ? "page" : undefined}>
                      {item.name}
                    </Link>
                    {item.subcategories && item.subcategories.length > 0 && (
                      <BsChevronDown
                        onClick={(event) =>
                          handleDropdownClick(item.name, event)
                        }
                        className={`font-semibold w-2/12 text-xl self-end text-end ${
                          expandedDropdown === item.name
                            ? "transform rotate-180"
                            : ""
                        }`}
                      />
                    )}
                  </div>
                  {expandedDropdown === item.name &&
                    item.subcategories &&
                    item.subcategories.length > 0 && (
                      <div className="space-y-2 mt-2 mx-4">
                        {item.name !== "More"
                          ? item.subcategories.map((subcategory) => (
                              <Link
                                to={`/product-category/${item.name.toLowerCase()}/${subcategory.toLowerCase()}`}
                                onClick={close}
                                key={subcategory}
                                className="text-gray-400 border-b border-gray-200 py-2 cursor-pointer block">
                                {subcategory}
                              </Link>
                            ))
                          : item.subcategories.map((section) => (
                              <div key={section.section}>
                                <p className="font-semibold py-2 whitespace-nowrap">
                                  {section.section}
                                </p>
                                {section.items &&
                                  section.items.map((subcategory) => (
                                    <Link
                                      key={subcategory}
                                      onClick={close}
                                      to={`/product-category/${item.name.toLowerCase()}/${subcategory.toLowerCase()}`}
                                      className="text-gray-400 border-b border-gray-200 py-2 cursor-pointer block">
                                      {subcategory}
                                    </Link>
                                  ))}
                              </div>
                            ))}
                      </div>
                    )}
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
