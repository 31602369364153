import React, { createContext, useState } from "react";

export const ShippingDetailsContext = createContext();

function ShippingDetailsProvider({ children }) {
  const [formData, setFormData] = useState({
    invoice: "",
    uid: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    deliveryAddress: "",
    location: "",
    house: "",
    deliveryComments: "",
    paymethod: "",
  });
  
  console.log("Form data context", formData);

  const [formIncomplete, setFormIncomplete] = useState(true);
  return (
    <ShippingDetailsContext.Provider
      value={{
        formData,
        setFormData,
        formIncomplete,
        setFormIncomplete,
      }}>
      {children}
    </ShippingDetailsContext.Provider>
  );
}

export default ShippingDetailsProvider;
