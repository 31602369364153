import React from "react";

const RegisterForm = () => {
  return (
    <div className="lg:w-1/2 p-8 border-r sm:w-full">
      <h2 className="text-2xl font-semibold mb-4">REGISTER</h2>
      <form>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-600">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-600">
            Password
          </label>
          <input
            type="password"
            id="reg-password"
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>
        <p className="text-sm text-gray-600 mb-4">
          Your personal data will be used to support your experience throughout
          this website, to manage access to your account, and for other purposes
          described in our privacy policy.
        </p>
        <button
          type="submit"
          className="bg-orange-600 text-white py-2 px-8 hover:bg-black">
          REGISTER
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
