import React, { useContext } from "react";
import axios from "axios";
import CartFullscreen from "../components/CartFullscreen";
import { CartContext } from "../contexts/CartContext";
import ErrorMessage from "../components/ErrorMessage";
import { AuthContext } from "../contexts/AuthContext";

export const CartStep = ({ onNext }) => {
  const { clearCart, totalQuantity, totalAmount, cart } =
    useContext(CartContext);
  const { userID, invoiceNumber } = useContext(AuthContext);

 
  // TODO:: Add coupoun logic if existing
  const discount = 0;


  const addToCart = () => {
    onNext();
  };

  return (
    <div>
      {/* Cart step content */}
      <div className="mx-auto py-5 lg:flex sm:flex-colum gap-6">
        {totalQuantity > 0 ? (
          <CartFullscreen cart={cart} />
        ) : (
          <div className="w-2/3">
            <ErrorMessage message={"Cart is empty"} />
          </div>
        )}
        <div className="lg:w-1/3">
          <div className="flex flex-col gap-6 border-b-[1px] border-gray-400 pb-6 ">
            <h2 className="text-2xl font-semibold">Cart Total</h2>
            <p className="justify-between flex font-semibold">
              Subtotal <span>KES {parseFloat(totalAmount).toFixed(2)}</span>
            </p>
            <p className="justify-between flex font-normal">
              No. of items <span>{totalQuantity}</span>
            </p>
            <p className="flex items-start gap-4 text-base">
              Shipping Address <span></span>
            </p>
          </div>
          <div className="py-2 w-full">
            <div className="py-4">Coupon</div>
            <input className="px-2 py-3 text-sm bg-gray-100 border border-gray-200 w-full" />
            <div className="flex justify-between w-full py-4">
              <div className="font-bold">Total</div>
              <div className="font-bold text-xl">
                KES. {parseFloat(totalAmount - 0).toFixed(2)}
              </div>
            </div>
            <button
              disabled={!totalQuantity}
              onClick={addToCart}
              className={`${
                totalQuantity
                  ? "bg-orange-600 hover:bg-orange-700"
                  : "bg-gray-400"
              } py-4 px-8 text-white w-full`}>
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
